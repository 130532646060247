import React from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYWxleGJyaW5rbWFuIiwiYSI6ImNqeGp5MHdmdjBkcHQzbm4zcmd0aDN2YXAifQ.o3D2ighcT2VOZ1hxL1SY9g';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.state = {
      width: props.width,
      height: props.height,
      latitude: props.latitude,
      longitude: props.longitude,
      zoom: props.zoom,
      theme: props.theme,
      waypoints: props.waypoints,
      complete: false,
    };
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.state.theme.mapStyle,
      center: [this.state.longitude, this.state.latitude],
      zoom: this.state.zoom,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });

    this.map.on('style.load', () => {
      this.addRoute(this.state.waypoints);
    });

    this.map.on('moveend', event => {
      // In the case of MouseMove or WheelScroll to manually change the view, leave alone,
      // otherwise try a best fit. Also, if we explicitly tell it to not move.
      if (event.originalEvent || event.stopPropagation) {
        return;
      }

      this.fitRoute(this.props.waypoints);
    });

    this.map.on('load', () => {
      this.setState({ complete: true });
    });

    this.fitRoute(this.props.waypoints);
  }

  componentDidUpdate(prevProps) {
    if (this.props.waypoints !== prevProps.waypoints) {
      this.setState({ waypoints: this.props.waypoints });
      this.addRoute(this.props.waypoints);
    }

    if (
      this.props.latitude !== prevProps.latitude ||
      this.props.longitude !== prevProps.longitude
    ) {
      this.setState({
        latitude: this.props.latitude,
        longitude: this.props.longitude,
      });
      this.map.flyTo({
        center: [this.props.longitude, this.props.latitude],
      });
    }

    if (this.props.theme !== prevProps.theme) {
      this.map.setStyle(this.props.theme.mapStyle);
      this.setState({
        theme: this.props.theme,
      });
    }
  }

  addRoute(waypoints) {
    if (!waypoints || !this.map) {
      return;
    }

    if (!this.map.isStyleLoaded()) {
      setTimeout(() => {
        this.addRoute(waypoints);
      }, 250);
      return;
    }

    if (this.map.getLayer('route')) {
      this.map.removeLayer('route').removeSource('route');
    }

    let routeColor = this.state.theme.routeColor;
    this.map.addLayer({
      id: 'route',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: waypoints,
          },
        },
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': routeColor,
        'line-width': 5,
      },
    });
  }

  fitRoute(waypoints) {
    if (!waypoints || waypoints.length === 0) {
      return;
    }

    let minLatitude = waypoints[0][1];
    let maxLatitude = waypoints[0][1];
    let minLongitude = waypoints[0][0];
    let maxLongitude = waypoints[0][0];

    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i][1] > maxLatitude) {
        maxLatitude = waypoints[i][1];
      }
      if (waypoints[i][1] < minLatitude) {
        minLatitude = waypoints[i][1];
      }
      if (waypoints[i][0] > maxLongitude) {
        maxLongitude = waypoints[i][0];
      }
      if (waypoints[i][0] < minLongitude) {
        minLongitude = waypoints[i][0];
      }
    }

    let sw = new mapboxgl.LngLat(minLongitude, minLatitude);
    let ne = new mapboxgl.LngLat(maxLongitude, maxLatitude);
    var bounds = new mapboxgl.LngLatBounds(sw, ne);

    this.map.fitBounds(
      bounds,
      {
        padding: 20,
      },
      { stopPropagation: true }
    );
  }

  render() {
    // const { longitude, latitude, zoom } = this.state; // TODO: use this pattern elsewhere.
    return (
      <div>
        <div
          ref={reactMap => (this.mapContainer = reactMap)}
          style={{ height: this.state.height, width: this.state.width }}
        />
        {this.state.complete && (
          <div id="map-fully-loaded" style={{ height: `0px` }}>
            &nbsp;
          </div>
        )}
      </div>
    );
  }
}

export default Map;
