import React from 'react';
import Map from './map';

class Poster extends React.Component {
  constructor(props) {
    super(props);
    this.reactMap = React.createRef();
    this.state = {
      width: props.width,
      height: props.height,
      latitude: props.latitude,
      longitude: props.longitude,
      zoom: props.zoom,
      theme: props.theme,
      waypoints: props.waypoints,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.waypoints !== prevProps.waypoints) {
      this.setState({ waypoints: this.props.waypoints });
    }

    if (
      this.props.latitude !== prevProps.latitude ||
      this.props.longitude !== prevProps.longitude
    ) {
      this.setState({
        latitude: this.props.latitude,
        longitude: this.props.longitude,
      });
    }

    if (this.props.theme !== prevProps.theme) {
      this.setState({
        theme: this.props.theme,
      });
    }
  }

  render() {
    return (
      <div id="poster">
        <div
          className="poster-wrapper"
          style={{
            borderColor: this.state.theme.borderColor,
            backgroundColor: this.state.theme.backgroundColor,
            width: this.state.width,
          }}
        >
          <Map
            width={this.state.width}
            height={this.state.height}
            theme={this.state.theme}
            waypoints={this.state.waypoints}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            zoom={this.state.zoom}
          />
          <div className="poster-title">
            <div className="poster-meta-group-1">
              <h2>{this.props.title}</h2>
              <h3>{this.props.subtitle}</h3>
            </div>
            <div className="poster-meta-group-2">
              <p>
                <strong>{this.props.field1.label}</strong>{' '}
                {this.props.field1.value}
              </p>
              <p>
                <strong>{this.props.field2.label}</strong>{' '}
                {this.props.field2.value}
              </p>
            </div>
            <div className="poster-meta-group-3">
              <p>
                <strong>{this.props.field3.label}</strong>{' '}
                {this.props.field3.value}
              </p>
              <p>
                <strong>{this.props.field4.label}</strong>{' '}
                {this.props.field4.value}
              </p>
            </div>
            <div className="poster-meta-group-4">
              <p>
                <strong>{this.props.field5.label}</strong>{' '}
                {this.props.field5.value}
              </p>
              <p>
                <strong>{this.props.field6.label}</strong>{' '}
                {this.props.field6.value}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Poster;
