import React from 'react';
import App from '../components/app';
import Layout from '../components/layout';
import SEO from '../components/seo';

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Create Your Work of Art" />
        <App />
      </Layout>
    );
  }
}

export default Index;
