import React from 'react';
import themes from '../styles/themes';

class ThemePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: this.props.theme,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    // Note: use currentTarget to prevent getting the span tag, if clicked.
    let themeName = event.currentTarget.dataset.theme;
    let theme = themes[themeName];
    this.setState({ theme: theme });
    this.props.onThemeChange(theme);
  }

  render() {
    const themeIcons = Object.keys(themes).map(theme => (
      <div
        key={themes[theme].id}
        onClick={this.handleClick}
        data-theme={theme}
        style={{
          height: '100px',
          width: '100px',
          border: `5px solid ${themes[theme].borderColor}`,
          marginBottom: '10px',
          backgroundImage: `url(${themes[theme].img})`,
          color: `${themes[theme].color}`,
        }}
      >
        <span
          style={{
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
            marginTop: '38px',
            marignBotton: '30px',
          }}
        >
          {theme}
        </span>
      </div>
    ));

    return <div>{themeIcons}</div>;
  }
}

export default ThemePicker;
