let themes = {};
themes.minimo = {
  id: 1,
  mapStyle: 'mapbox://styles/alexbrinkman/cjxv0ih8a05cc1co8922gvkki',
  routeColor: '#590159',
  borderColor: '#800080',
  backgroundColor: '#ffffff',
  img: '/images/minimo.png',
  color: '#000000',
};
themes.aqua = {
  id: 2,
  mapStyle: 'mapbox://styles/alexbrinkman/cjxv0swup9n391cpolcxx2or5',
  routeColor: '#FFFFFF',
  borderColor: '#3c7d60',
  backgroundColor: '#eeeeee',
  img: '/images/aqua.png',
  color: '#ffffff',
};

export default themes;
