class GPXParser {
  constructor() {
    this.xmlSource = '';
    this.metadata = {};
    this.waypoints = [];
    this.tracks = [];
    this.routes = [];
  }

  parse(string) {
    var keepThis = this;
    var domParser = new DOMParser();
    this.xmlSource = domParser.parseFromString(string, 'text/xml');

    let metadata = this.xmlSource.querySelector('metadata');
    if (metadata != null) {
      this.metadata.name = this.getElementValue(metadata, 'name');
      this.metadata.desc = this.getElementValue(metadata, 'desc');
      this.metadata.time = this.getElementValue(metadata, 'time');

      let author = {};
      let authorElem = metadata.querySelector('author');
      if (authorElem != null) {
        author.name = this.getElementValue(authorElem, 'name');

        author.email = {};
        let emailElem = authorElem.querySelector('email');
        if (emailElem != null) {
          author.email.id = emailElem.getAttribute('id');
          author.email.domain = emailElem.getAttribute('domain');
        }

        let link = {};
        let linkElem = authorElem.querySelector('link');
        if (linkElem != null) {
          link.href = linkElem.getAttribute('href');
          link.text = this.getElementValue(linkElem, 'text');
          link.type = this.getElementValue(linkElem, 'type');
        }
        author.link = link;
      }
      this.metadata.author = author;

      let link = {};
      let linkElem = metadata.querySelector('link');
      if (linkElem != null) {
        link.href = linkElem.getAttribute('href');
        link.text = this.getElementValue(linkElem, 'text');
        link.type = this.getElementValue(linkElem, 'type');
        this.metadata.link = link;
      }
    }

    var wpts = [].slice.call(this.xmlSource.querySelectorAll('wpt'));
    for (let idx in wpts) {
      var wpt = wpts[idx];
      let pt = {};
      pt.name = keepThis.getElementValue(wpt, 'name');
      pt.lat = parseFloat(wpt.getAttribute('lat'));
      pt.lon = parseFloat(wpt.getAttribute('lon'));
      pt.ele = parseFloat(keepThis.getElementValue(wpt, 'ele'));
      pt.cmt = keepThis.getElementValue(wpt, 'cmt');
      pt.desc = keepThis.getElementValue(wpt, 'desc');
      keepThis.waypoints.push(pt);
    }

    var rtes = [].slice.call(this.xmlSource.querySelectorAll('rte'));
    for (let idx in rtes) {
      var rte = rtes[idx];
      let route = {};
      route.name = keepThis.getElementValue(rte, 'name');
      route.cmt = keepThis.getElementValue(rte, 'cmt');
      route.desc = keepThis.getElementValue(rte, 'desc');
      route.src = keepThis.getElementValue(rte, 'src');
      route.number = keepThis.getElementValue(rte, 'number');
      route.link = keepThis.getElementValue(rte, 'link');
      route.type = keepThis.getElementValue(rte, 'type');

      let routepoints = [];
      var rtepts = [].slice.call(rte.querySelectorAll('rtept'));

      for (let idxIn in rtepts) {
        var rtept = rtepts[idxIn];
        let pt = {};
        pt.lat = parseFloat(rtept.getAttribute('lat'));
        pt.lon = parseFloat(rtept.getAttribute('lon'));
        pt.ele = parseFloat(keepThis.getElementValue(rtept, 'ele'));
        routepoints.push(pt);
      }

      route.distance = keepThis.calcDistance(routepoints);
      route.elevation = keepThis.calcElevation(routepoints);
      route.points = routepoints;
      keepThis.routes.push(route);
    }

    var trks = [].slice.call(this.xmlSource.querySelectorAll('trk'));
    for (let idx in trks) {
      var trk = trks[idx];
      let track = {};

      track.name = keepThis.getElementValue(trk, 'name');
      track.cmt = keepThis.getElementValue(trk, 'cmt');
      track.desc = keepThis.getElementValue(trk, 'desc');
      track.src = keepThis.getElementValue(trk, 'src');
      track.number = keepThis.getElementValue(trk, 'number');
      track.link = keepThis.getElementValue(trk, 'link');
      track.type = keepThis.getElementValue(trk, 'type');

      let trackpoints = [];
      var trkpts = [].slice.call(trk.querySelectorAll('trkpt'));
      for (let idxIn in trkpts) {
        var trkpt = trkpts[idxIn];
        let pt = {};
        pt.lat = parseFloat(trkpt.getAttribute('lat'));
        pt.lon = parseFloat(trkpt.getAttribute('lon'));
        pt.ele = parseFloat(keepThis.getElementValue(trkpt, 'ele'));
        pt.time = keepThis.getElementValue(trkpt, 'time');
        trackpoints.push(pt);
      }
      track.distance = keepThis.calcDistance(trackpoints);
      track.elevation = keepThis.calcElevation(trackpoints);
      track.points = trackpoints;
      keepThis.tracks.push(track);
    }
  }

  getElementValue(parent, needle) {
    let elem = parent.querySelector(' :scope > ' + needle);
    if (elem != null) {
      return elem.innerHTML;
    }
    return elem;
  }

  calcDistance(points) {
    let distance = {};
    let totalDistance = 0;
    let cumulDistance = [];
    for (var i = 0; i < points.length - 1; i++) {
      totalDistance += this.calcDistanceBetween(points[i], points[i + 1]);
      cumulDistance[i] = totalDistance;
    }
    cumulDistance[points.length - 1] = totalDistance;

    distance.total = totalDistance;
    distance.cumul = cumulDistance;

    return distance;
  }

  calcDistanceBetween(wpt1, wpt2) {
    let latlng1 = {};
    latlng1.lat = wpt1.lat;
    latlng1.lon = wpt1.lon;
    let latlng2 = {};
    latlng2.lat = wpt2.lat;
    latlng2.lon = wpt2.lon;
    var rad = Math.PI / 180,
      lat1 = latlng1.lat * rad,
      lat2 = latlng2.lat * rad,
      sinDLat = Math.sin(((latlng2.lat - latlng1.lat) * rad) / 2),
      sinDLon = Math.sin(((latlng2.lon - latlng1.lon) * rad) / 2),
      a =
        sinDLat * sinDLat + Math.cos(lat1) * Math.cos(lat2) * sinDLon * sinDLon,
      c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return 6371000 * c;
  }

  calcElevation(points) {
    var dp = 0,
      dm = 0,
      ret = {};

    for (var i = 0; i < points.length - 1; i++) {
      var diff = parseFloat(points[i + 1].ele) - parseFloat(points[i].ele);

      if (diff < 0) {
        dm += diff;
      } else if (diff > 0) {
        dp += diff;
      }
    }

    var elevation = [];
    var sum = 0;

    for (var j = 0, len = points.length; j < len; j++) {
      var ele = parseFloat(points[j].ele);
      elevation.push(ele);
      sum += ele;
    }

    ret.max = Math.max.apply(null, elevation);
    ret.min = Math.min.apply(null, elevation);
    ret.pos = Math.abs(dp);
    ret.neg = Math.abs(dm);
    ret.avg = sum / elevation.length;

    return ret;
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  distanceInMiles() {
    const METERS_CONVERSION = 0.000621371;
    if (this.tracks && this.tracks[0]) {
      let distance = this.tracks[0].distance.total * METERS_CONVERSION;
      return Math.round(distance * 100) / 100;
    }
    return 0;
  }

  duration() {
    if (this.tracks && this.tracks[0]) {
      let points = this.tracks[0].points;
      let firstTime = new Date(points[0].time);
      let lastTime = new Date(points[points.length - 1].time);
      return this.parseMilliseconds(lastTime - firstTime);
    }
    return '0:00:00';
  }

  pace(duration, miles) {
    if (this.tracks && this.tracks[0]) {
      let points = this.tracks[0].points;
      let firstTime = new Date(points[0].time);
      let lastTime = new Date(points[points.length - 1].time);
      return this.parseMilliseconds((lastTime - firstTime) / miles);
    }
    return '0:00:00';
  }

  elevationGainInFeet() {
    const METERS_CONVERSION = 3.28084;
    if (this.tracks && this.tracks[0]) {
      let elevation = this.tracks[0].elevation.pos * METERS_CONVERSION;
      return Math.round(elevation);
    }
    return 0;
  }

  elevationLostInFeet() {
    const METERS_CONVERSION = 3.28084;
    if (this.tracks && this.tracks[0]) {
      let elevation = this.tracks[0].elevation.neg * METERS_CONVERSION;
      return Math.round(elevation);
    }
    return 0;
  }

  parseMilliseconds(milliseconds) {
    //Get hours from milliseconds
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    return h + ':' + m + ':' + s;
  }
}

export default GPXParser;
