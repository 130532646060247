import React from 'react';
import Dropzone from 'react-dropzone';
import GPXParser from '../utils/gpx_parser';
import Poster from './poster';
import InputForm from './input-form';
import ThemePicker from './theme-picker';
import themes from '../styles/themes';
import { navigate } from 'gatsby';
import UUIDGenerator from '../utils/uuid_generator';

class App extends React.Component {
  constructor(props) {
    super(props);
    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    let today = new Date().toLocaleDateString('en-US', options);

    this.state = {
      id: new UUIDGenerator().uuidv4(),
      title: 'Your Route',
      subtitle: `${today}`,
      date: '',
      distance: '',
      latitude: 42.6403,
      longitude: -109.3742,
      zoom: 8,
      field1: { label: '', value: '' },
      field2: { label: '', value: '' },
      field3: { label: '', value: '' },
      field4: { label: '', value: '' },
      field5: { label: '', value: '' },
      field6: { label: '', value: '' },
      waypoints: [],
      theme: themes.minimo,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && window.localStorage.getItem('map')) {
      this.setState(JSON.parse(window.localStorage.getItem('map')));
    }
  }

  checkout() {
    navigate('/checkout');
  }

  parseData(files) {
    let self = this;
    let file = files[0];
    let reader = new FileReader();

    reader.onload = function() {
      let gpx = new GPXParser();
      gpx.parse(reader.result);

      // TODO: dry up these options, I'm using them all over the place.
      var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      let eventDate = new Date(gpx.metadata.time);
      let parsedEventDate = eventDate.toLocaleDateString('en-US', options);

      let distance = gpx.distanceInMiles();
      let elevation = gpx.elevationGainInFeet();
      let duration = gpx.duration();
      let pace = gpx.pace(duration, distance);

      self.setState(
        {
          title: gpx.tracks[0].name,
          subtitle: parsedEventDate,
          date: parsedEventDate,
          latitude: gpx.tracks[0].points[0].lat,
          longitude: gpx.tracks[0].points[0].lon,
          field1: {
            label: 'Distance',
            value: `${gpx.distanceInMiles()} miles`,
          },
          field2: { label: 'Time', value: `${duration}` },
          field3: { label: 'Pace', value: `${pace}` },
          field4: {
            label: 'Elevation',
            value: `${elevation} feet`,
          },
          field5: { label: '', value: '' },
          field6: { label: '', value: '' },
          waypoints: gpx.tracks[0].points.map(point => [point.lon, point.lat]),
        },
        () => self.updateLocalStorage()
      );
    };

    reader.onerror = function() {
      console.log(reader.error);
    };

    reader.readAsText(file);
  }

  handleFieldChange(fieldUpdate) {
    this.setState(fieldUpdate, () => this.updateLocalStorage());
  }

  handleThemeChange(theme) {
    this.setState({ theme: theme }, () => this.updateLocalStorage());
  }

  updateLocalStorage() {
    if (typeof window !== 'undefined') {
      localStorage.setItem('map', JSON.stringify(this.state));
    }
  }

  render() {
    return (
      <div className="grid-container">
        <div className="grid-control-items">
          <h2>Step 1: Add Your Route File</h2>
          <Dropzone
            onDrop={acceptedFiles => this.parseData(acceptedFiles)}
            accept="application/gpx+xml, text/xml, application/xml, application/octet-stream"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button>Add GPX File</button>
              </div>
            )}
          </Dropzone>
          <div style={{ marginBottom: `30px` }}></div>
          <h2>Step 2: Choose A Theme</h2>
          <ThemePicker
            selectedTheme={this.state.theme}
            onThemeChange={this.handleThemeChange}
          />
          <div style={{ marginBottom: `30px` }}></div>
          <h2>Step 3: Customize Your Map Data</h2>
          <InputForm
            title={this.state.title}
            subtitle={this.state.subtitle}
            field1={this.state.field1}
            field2={this.state.field2}
            field3={this.state.field3}
            field4={this.state.field4}
            field5={this.state.field5}
            field6={this.state.field6}
            handleFieldChange={this.handleFieldChange}
          />
          <div style={{ marginBottom: `30px` }}></div>
          <h2>Step 4: Order Your Work of Art</h2>
          <div id="error-message"></div>
          <button onClick={this.checkout}>Checkout</button>
        </div>
        <div className="grid-map">
          <Poster
            width={`783px`}
            height={`400px`}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            zoom={this.state.zoom}
            title={this.state.title}
            subtitle={this.state.subtitle}
            field1={this.state.field1}
            field2={this.state.field2}
            field3={this.state.field3}
            field4={this.state.field4}
            field5={this.state.field5}
            field6={this.state.field6}
            waypoints={this.state.waypoints}
            theme={this.state.theme}
          />
        </div>
      </div>
    );
  }
}

export default App;
