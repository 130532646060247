import React from 'react';

class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.reactMap = React.createRef();
    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      field1: props.field1,
      field2: props.field2,
      field3: props.field3,
      field4: props.field4,
      field5: props.field5,
      field6: props.field6,
    };
    this.updateTitle = this.updateTitle.bind(this);
    this.updateSubtitle = this.updateSubtitle.bind(this);
    this.updateMap = this.updateMap.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      this.setState({ title: this.props.title });
    }
    if (this.props.subtitle !== prevProps.subtitle) {
      this.setState({ subtitle: this.props.subtitle });
    }
    if (this.props.field1 !== prevProps.field1) {
      this.setState({ field1: this.props.field1 });
    }
    if (this.props.field2 !== prevProps.field2) {
      this.setState({ field2: this.props.field2 });
    }
    if (this.props.field3 !== prevProps.field3) {
      this.setState({ field3: this.props.field3 });
    }
    if (this.props.field4 !== prevProps.field4) {
      this.setState({ field4: this.props.field4 });
    }
    if (this.props.field5 !== prevProps.field5) {
      this.setState({ field5: this.props.field5 });
    }
    if (this.props.field6 !== prevProps.field6) {
      this.setState({ field6: this.props.field6 });
    }
  }

  updateTitle(event) {
    let fieldUpdate = { title: event.target.value };
    this.setState(fieldUpdate);
    this.props.handleFieldChange(fieldUpdate);
  }

  updateSubtitle(event) {
    let fieldUpdate = { subtitle: event.target.value };
    this.setState(fieldUpdate);
    this.props.handleFieldChange(fieldUpdate);
  }

  updateMap(event) {
    // I have to get both the label and the value as a pair to send back in the hander.
    // So, query by data key and pull out needed values.
    let key = event.target.dataset.key;
    let label = document.querySelectorAll(
      `[data-key=${key}][data-type=label]`
    )[0];
    let value = document.querySelectorAll(
      `[data-key=${key}][data-type=value]`
    )[0];

    let fieldUpdate = {};
    fieldUpdate[key] = { label: label.value, value: value.value };

    this.setState(fieldUpdate);
    this.props.handleFieldChange(fieldUpdate);
  }

  render() {
    return (
      <div>
        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateTitle}
            value={this.state.title}
            placeholder={`Title`}
            className="input-grid-full-size"
          />
        </div>
        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateSubtitle}
            value={this.state.subtitle}
            placeholder={`Subtitle`}
            className="input-grid-full-size"
            style={{ marginBottom: `40px` }}
          />
        </div>
        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field1.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field1"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field1.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field1"
            className="input-grid-value"
          />
        </div>

        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field2.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field2"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field2.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field2"
            className="input-grid-value"
          />
        </div>

        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field3.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field3"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field3.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field3"
            className="input-grid-value"
          />
        </div>

        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field4.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field4"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field4.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field4"
            className="input-grid-value"
          />
        </div>

        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field5.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field5"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field5.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field5"
            className="input-grid-value"
          />
        </div>

        <div className="input-grid-container">
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field6.label}
            placeholder={`Label`}
            data-type="label"
            data-key="field6"
            className="input-grid-label"
          />
          <input
            type="text"
            onChange={this.updateMap}
            value={this.state.field6.value}
            placeholder={`Value`}
            data-type="value"
            data-key="field6"
            className="input-grid-value"
          />
        </div>
      </div>
    );
  }
}

export default InputForm;
